import React from 'react'

export default function Modal({ children, style, visible }) {

    return (
        <div style={{ position: 'absolute', alignItems: 'center', justifyContent: 'center', display: visible ? 'flex' : "none", zIndex: 999,height:'100%',width:'100%',  background: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))'}}>
            <div style={{ padding: '25px', backgroundColor: '#fff', borderRadius: '12px', display: 'flex', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', ...style }}>
                {children}
            </div>
        </div>

    )
}
