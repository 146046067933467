import axios from "axios";
import { API_URL } from "./index";

export default
    {
        SaveBolParaCampaignResponse(token,campaignID,data) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/save-bol-para-campaign-response`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    campaignID:campaignID,
                    data:data
                }
            });
        }
    }