import './App.css';
import Form from './components/Form';
import logo from './assets/ep_logo.png'

function App() {

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token').split(":")[0];
  const campaignID = searchParams.get('token').split(":")[1];
  console.log("Token:",token);
  console.log("CampaignID:",campaignID);
  return (
    <div className='main-container'>
      <div style={{
        width: '100%',
        minWidth: '100%',
        height: '56px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px'
      }}>
        <img
          src={logo}
          alt="Logo"
          
        />
      </div>
      <Form token={token} campaignID={campaignID}/>
    </div>
  );
}

export default App;
