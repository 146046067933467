// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  background-color: #FAF6F5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

p {
  font-family: "DM Sans", sans-serif;
}

span {
  font-family: "DM Sans", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".main-container {\r\n  background-color: #FAF6F5;\r\n  width: 100%;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 50px;\r\n}\r\n\r\np {\r\n  font-family: \"DM Sans\", sans-serif;\r\n}\r\n\r\nspan {\r\n  font-family: \"DM Sans\", sans-serif;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
